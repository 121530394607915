* {
  padding: 0;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Oswald", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

::-webkit-calendar-picker-indicator {
  color: transparent;
  opacity: 1;
  /* background: url(./assets/icons/calendar.svg) no-repeat; */
  background-size: contain;
  cursor: pointer;
}

input[type=date] {
  border: none;
  outline: none;
}

html {
  height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap");